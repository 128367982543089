import { Collapse, Stack } from "@mui/material"
import Box from "@mui/material/Box"
import { PropsWithChildren, ReactNode, useState } from "react"

import { SearchController } from "."
import { ChildRoute, LayoutContainer } from "./LayoutContainer"
import { Props as SearchProps } from "./SearchController"

type SearchOptions = Pick<
  SearchProps,
  "setSearchValue" | "searchResultComponent" | "data-testid"
>

interface Props {
  routePattern: string
  childRoutes: ChildRoute[]
  header: ReactNode
  searchControllerOptions?: SearchOptions
  "data-testid": string
}

export const SplitHeaderLayout = ({
  routePattern,
  childRoutes,
  header,
  children: body,
  searchControllerOptions,
  "data-testid": testId,
}: PropsWithChildren<Props>) => {
  const [isSearchActive, setIsSearchActive] = useState(false)

  return (
    <LayoutContainer
      routePattern={routePattern}
      childRoutes={childRoutes}
      data-testid={testId}
    >
      <Collapse in={!isSearchActive}>
        <Box p={3} pb={6} color="primary.contrastText">
          {header}
        </Box>
      </Collapse>

      <Stack
        // "display: flex" in the Collapse wrapper used to hide the header
        // causes the header to overlay the content; This prevents that.
        position="relative"
        mt={isSearchActive ? 0 : -3}
        p={3}
        gap={3}
        bgcolor="background.background0"
        borderRadius={({ spacing }) => spacing(3, 3, 0, 0)}
      >
        {searchControllerOptions ? (
          <SearchController
            setIsSearchActive={setIsSearchActive}
            isSearchActive={isSearchActive}
            {...searchControllerOptions}
          >
            {body}
          </SearchController>
        ) : (
          body
        )}
      </Stack>
    </LayoutContainer>
  )
}
