export const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <path
      d="M31.6668 11.183L29.3168 8.83301L20.0002 18.1497L10.6835 8.83301L8.3335 11.183L17.6502 20.4997L8.3335 29.8163L10.6835 32.1663L20.0002 22.8497L29.3168 32.1663L31.6668 29.8163L22.3502 20.4997L31.6668 11.183Z"
      fill="currentColor"
    />
  </svg>
)

export const Trade = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.90999 22.82C3.81999 22.82 2.88999 22.47 2.20999 21.79C1.23999 20.82 0.939994 19.34 1.36999 17.62L3.84999 7.69001C4.27999 5.97001 5.95999 4.30001 7.66999 3.87001L17.6 1.39001C19.32 0.960013 20.8 1.26001 21.77 2.23001C22.74 3.20001 23.04 4.68001 22.61 6.40001L20.13 16.33C19.7 18.05 18.02 19.72 16.31 20.15L6.37999 22.63C5.86999 22.75 5.37999 22.82 4.90999 22.82ZM17.98 2.83001L8.04999 5.32001C6.87999 5.61001 5.60999 6.88001 5.30999 8.05001L2.82999 17.98C2.52999 19.17 2.68999 20.14 3.26999 20.73C3.84999 21.31 4.82999 21.47 6.01999 21.17L15.95 18.69C17.12 18.4 18.39 17.12 18.68 15.96L21.16 6.03001C21.46 4.84001 21.3 3.87001 20.72 3.28001C20.14 2.69001 19.17 2.54001 17.98 2.83001Z"
      fill="currentColor"
    />
    <path
      d="M12 16.25C9.66 16.25 7.75 14.34 7.75 12C7.75 9.66 9.66 7.75 12 7.75C14.34 7.75 16.25 9.66 16.25 12C16.25 14.34 14.34 16.25 12 16.25ZM12 9.25C10.48 9.25 9.25 10.48 9.25 12C9.25 13.52 10.48 14.75 12 14.75C13.52 14.75 14.75 13.52 14.75 12C14.75 10.48 13.52 9.25 12 9.25Z"
      fill="currentColor"
    />
  </svg>
)

export const Trend = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/outline/trend-up">
      <g id="trend-up">
        <path
          fill="currentColor"
          id="Vector"
          d="M8.00043 15.2499C7.81043 15.2499 7.62043 15.1799 7.47043 15.0299C7.18043 14.7399 7.18043 14.2599 7.47043 13.9699L10.6704 10.7699C10.8304 10.6099 11.0404 10.5299 11.2704 10.5499C11.4904 10.5699 11.6904 10.6899 11.8204 10.8799L12.9104 12.5199L16.4604 8.96994C16.7504 8.67994 17.2304 8.67994 17.5204 8.96994C17.8104 9.25994 17.8104 9.73994 17.5204 10.0299L13.3204 14.2299C13.1604 14.3899 12.9504 14.4699 12.7204 14.4499C12.5004 14.4299 12.3004 14.3099 12.1704 14.1199L11.0804 12.4799L8.53043 15.0299C8.38043 15.1799 8.19043 15.2499 8.00043 15.2499Z"
          fillOpacity="0.87"
        />
        <path
          id="Vector_2"
          d="M17 12.25C16.59 12.25 16.25 11.91 16.25 11.5V10.25H15C14.59 10.25 14.25 9.91 14.25 9.5C14.25 9.09 14.59 8.75 15 8.75H17C17.41 8.75 17.75 9.09 17.75 9.5V11.5C17.75 11.91 17.41 12.25 17 12.25Z"
          fill="currentColor"
          fillOpacity="0.87"
        />
        <path
          id="Vector_3"
          d="M15.5 22.75H9.5C4.07 22.75 1.75 20.43 1.75 15V9C1.75 3.57 4.07 1.25 9.5 1.25H15.5C20.93 1.25 23.25 3.57 23.25 9V15C23.25 20.43 20.93 22.75 15.5 22.75ZM9.5 2.75C4.89 2.75 3.25 4.39 3.25 9V15C3.25 19.61 4.89 21.25 9.5 21.25H15.5C20.11 21.25 21.75 19.61 21.75 15V9C21.75 4.39 20.11 2.75 15.5 2.75H9.5Z"
          fill="currentColor"
          fillOpacity="0.87"
        />
      </g>
    </g>
  </svg>
)

export const Search = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
    <path
      d="M21.5068 19.5065H20.4534L20.0801 19.1465C21.3868 17.6265 22.1734 15.6532 22.1734 13.5065C22.1734 8.71984 18.2934 4.83984 13.5068 4.83984C8.72009 4.83984 4.84009 8.71984 4.84009 13.5065C4.84009 18.2932 8.72009 22.1732 13.5068 22.1732C15.6534 22.1732 17.6268 21.3865 19.1468 20.0798L19.5068 20.4532V21.5065L26.1734 28.1598L28.1601 26.1732L21.5068 19.5065ZM13.5068 19.5065C10.1868 19.5065 7.50675 16.8265 7.50675 13.5065C7.50675 10.1865 10.1868 7.50651 13.5068 7.50651C16.8268 7.50651 19.5068 10.1865 19.5068 13.5065C19.5068 16.8265 16.8268 19.5065 13.5068 19.5065Z"
      fill="currentColor"
    />
  </svg>
)

export const Star = () => (
  <svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5001 24.1935L24.7401 29.1668L22.5534 19.7935L29.8334 13.4868L20.2467 12.6735L16.5001 3.8335L12.7534 12.6735L3.16675 13.4868L10.4467 19.7935L8.26008 29.1668L16.5001 24.1935Z"
      fill="currentColor"
    />
  </svg>
)

export const ChevronLeft = () => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7049 7.91L14.2949 6.5L8.29492 12.5L14.2949 18.5L15.7049 17.09L11.1249 12.5L15.7049 7.91Z"
      fill="currentColor"
    />
  </svg>
)

export const Equivalent = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#122C47" />
    <path
      d="M14.2588 10.7632V11.6279H9.7793V10.7632H14.2588ZM14.2588 12.9922V13.8569H9.7793V12.9922H14.2588Z"
      fill="currentColor"
    />
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#143F65" />
  </svg>
)

export const SearchDark = () => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M6.70817 12.6878C3.41234 12.6878 0.729004 10.0045 0.729004 6.70866C0.729004 3.41283 3.41234 0.729492 6.70817 0.729492C10.004 0.729492 12.6873 3.41283 12.6873 6.70866C12.6873 10.0045 10.004 12.6878 6.70817 12.6878ZM6.70817 1.60449C3.89067 1.60449 1.604 3.89699 1.604 6.70866C1.604 9.52033 3.89067 11.8128 6.70817 11.8128C9.52567 11.8128 11.8123 9.52033 11.8123 6.70866C11.8123 3.89699 9.52567 1.60449 6.70817 1.60449Z"
      fill="currentColor"
    />
    <path
      id="Vector_2"
      d="M12.8332 13.2707C12.7223 13.2707 12.6115 13.2298 12.524 13.1423L11.3573 11.9757C11.1882 11.8065 11.1882 11.5265 11.3573 11.3573C11.5265 11.1882 11.8065 11.1882 11.9757 11.3573L13.1423 12.524C13.3115 12.6932 13.3115 12.9732 13.1423 13.1423C13.0548 13.2298 12.944 13.2707 12.8332 13.2707Z"
      fill="currentColor"
    />
  </svg>
)

export const MoonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 12 12"
  >
    <path
      fill="#D8D4EA"
      fillRule="evenodd"
      d="M3.881.015L4.267.4c-.706 1.735-.503 3.708.543 5.263C5.857 7.218 7.608 8.15 9.481 8.148c.727 0 1.446-.14 2.118-.415l.386.386c-.947 2.35-3.229 3.887-5.763 3.881C3.246 12 .686 9.893.115 6.971c-.57-2.92 1.009-5.837 3.766-6.956zm7.883 3.278l.236.235.236-.235c.13-.13.34-.13.471 0 .13.13.13.341 0 .471L12.472 4l.235.237c.13.13.13.34 0 .471-.13.13-.341.13-.471 0L12 4.471l-.236.236c-.13.13-.34.13-.471 0-.13-.13-.13-.341 0-.471l.235-.237-.235-.235c-.13-.13-.13-.34 0-.471.13-.13.341-.13.471 0zM7.146 1.439l.354.354.354-.354c.195-.195.511-.195.707 0 .195.196.195.512 0 .707l-.353.353.353.355c.195.195.195.511 0 .707-.196.195-.512.195-.707 0L7.5 3.207l-.354.354c-.195.195-.511.195-.707 0-.195-.196-.195-.512 0-.707l.354-.354-.354-.354c-.195-.195-.195-.511 0-.707.196-.195.512-.195.707 0z"
    ></path>
  </svg>
)

export const SunIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 14 14"
  >
    <path
      fill="#FF8D00"
      fillRule="evenodd"
      d="M7 11.304c.304 0 .522.261.522.566v1.565c0 .304-.218.565-.522.565-.304 0-.522-.26-.522-.565V11.87c0-.305.218-.566.522-.566zm-3.826-1.26c.217-.174.522-.218.739 0 .217.217.217.565 0 .739l-1.13 1.13c-.087.13-.218.174-.348.174-.174 0-.305-.044-.392-.174-.217-.174-.217-.522 0-.696zm6.87-.044c.173-.174.521-.174.695.043l1.238 1.238c.15.185.13.477-.064.632-.217.217-.522.217-.74 0L10 10.739c-.174-.217-.174-.522.043-.739zM7 4.174c1.522 0 2.783 1.26 2.783 2.783 0 1.565-1.261 2.782-2.783 2.826-1.565 0-2.783-1.261-2.783-2.826 0-1.522 1.261-2.783 2.783-2.783zm0 1.13c-.913 0-1.696.74-1.696 1.653 0 .956.74 1.695 1.653 1.695.956 0 1.695-.739 1.695-1.695 0-.914-.739-1.653-1.652-1.653zm6.435 1.174c.304 0 .565.218.565.522 0 .304-.26.522-.565.522H11.87c-.305 0-.566-.218-.566-.522 0-.304.261-.522.566-.522zm-11.305 0c.305 0 .522.218.522.522 0 .304-.217.522-.522.522H.522C.217 7.522 0 7.304 0 7c0-.304.217-.522.522-.522zm9.151-4.455c.185-.15.477-.13.632.064.26.174.217.522 0 .74l-1.13 1.13c-.174.173-.479.173-.696 0-.217-.218-.217-.566 0-.74zm-9.238.064c.218-.217.522-.217.74 0l1.13 1.13c.174.218.174.479 0 .696-.217.217-.522.217-.74.044l-1.13-1.13c-.217-.218-.217-.566 0-.74zM7 0c.304 0 .522.217.522.522V2.13c0 .305-.218.522-.522.522-.304 0-.522-.217-.522-.478V.522C6.478.217 6.696 0 7 0z"
    ></path>
  </svg>
)

export const Eye = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M12.4999 16.3299C10.1099 16.3299 8.16992 14.3899 8.16992 11.9999C8.16992 9.60992 10.1099 7.66992 12.4999 7.66992C14.8899 7.66992 16.8299 9.60992 16.8299 11.9999C16.8299 14.3899 14.8899 16.3299 12.4999 16.3299ZM12.4999 9.16992C10.9399 9.16992 9.66992 10.4399 9.66992 11.9999C9.66992 13.5599 10.9399 14.8299 12.4999 14.8299C14.0599 14.8299 15.3299 13.5599 15.3299 11.9999C15.3299 10.4399 14.0599 9.16992 12.4999 9.16992Z"
      fill="currentColor"
    />
    <path
      id="Vector_2"
      d="M12.5001 21.02C8.74008 21.02 5.19008 18.82 2.75008 15C1.69008 13.35 1.69008 10.66 2.75008 8.99998C5.20008 5.17998 8.75008 2.97998 12.5001 2.97998C16.2501 2.97998 19.8001 5.17998 22.2401 8.99998C23.3001 10.65 23.3001 13.34 22.2401 15C19.8001 18.82 16.2501 21.02 12.5001 21.02ZM12.5001 4.47998C9.27008 4.47998 6.18008 6.41998 4.02008 9.80998C3.27008 10.98 3.27008 13.02 4.02008 14.19C6.18008 17.58 9.27008 19.52 12.5001 19.52C15.7301 19.52 18.8201 17.58 20.9801 14.19C21.7301 13.02 21.7301 10.98 20.9801 9.80998C18.8201 6.41998 15.7301 4.47998 12.5001 4.47998Z"
      fill="currentColor"
    />
  </svg>
)

export const Frame = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M12.6596 11.62C12.6296 11.62 12.6096 11.62 12.5796 11.62C12.5296 11.61 12.4596 11.61 12.3996 11.62C9.49957 11.53 7.30957 9.25 7.30957 6.44C7.30957 3.58 9.63957 1.25 12.4996 1.25C15.3596 1.25 17.6896 3.58 17.6896 6.44C17.6796 9.25 15.4796 11.53 12.6896 11.62C12.6796 11.62 12.6696 11.62 12.6596 11.62ZM12.4996 2.75C10.4696 2.75 8.80957 4.41 8.80957 6.44C8.80957 8.44 10.3696 10.05 12.3596 10.12C12.4096 10.11 12.5496 10.11 12.6796 10.12C14.6396 10.03 16.1796 8.42 16.1896 6.44C16.1896 4.41 14.5296 2.75 12.4996 2.75Z"
      fill="currentColor"
      fillOpacity="0.87"
    />
    <path
      id="Vector_2"
      d="M12.6696 22.55C10.7096 22.55 8.73961 22.05 7.24961 21.05C5.85961 20.13 5.09961 18.87 5.09961 17.5C5.09961 16.13 5.85961 14.86 7.24961 13.93C10.2496 11.94 15.1096 11.94 18.0896 13.93C19.4696 14.85 20.2396 16.11 20.2396 17.48C20.2396 18.85 19.4796 20.12 18.0896 21.05C16.5896 22.05 14.6296 22.55 12.6696 22.55ZM8.07961 15.19C7.11961 15.83 6.59961 16.65 6.59961 17.51C6.59961 18.36 7.12961 19.18 8.07961 19.81C10.5696 21.48 14.7696 21.48 17.2596 19.81C18.2196 19.17 18.7396 18.35 18.7396 17.49C18.7396 16.64 18.2096 15.82 17.2596 15.19C14.7696 13.53 10.5696 13.53 8.07961 15.19Z"
      fill="currentColor"
      fillOpacity="0.87"
    />
  </svg>
)
