import { Theme } from "@mui/material"

import { ChartIQThemeSettings } from "./types"

export const createChartTheme = ({ palette }: Theme): ChartIQThemeSettings => ({
  chart: {
    Background: {
      color: "transparent",
    },
    "Grid Lines": {
      color: palette.background.background1,
    },
    "Grid Dividers": {
      color: "transparent",
    },
    "Axis Text": {
      color: palette.text.secondary,
    },
  },
  chartTypes: {
    "Candle/Bar": {
      up: {
        color: palette.positive!.main,
        wick: palette.positive!.main,
        border: "transparent",
      },
      down: {
        color: palette.negative!.main,
        wick: palette.negative!.main,
        border: "transparent",
      },
      even: {
        color: palette.positive!.main,
        wick: palette.positive!.main,
        border: "transparent",
      },
    },
    Line: {
      color: palette.text.primary,
    },
    Mountain: {
      color: palette.text.primary,
      basecolor: palette.secondary.main,
    },
  },
})
