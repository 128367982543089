import { Components, Theme } from "@mui/material"

export const MuiTab: Required<Components<Theme>>["MuiTab"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body1,
      padding: 2,
      height: "100%",
      color: theme.palette.text.secondary,
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.primary.main,
      },
    }),
  },
}
