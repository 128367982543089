import { Box, Stack } from "@mui/material"
import { Key, ReactElement } from "react"

interface ListProps<T> {
  data: T[]
  keyField: keyof T
  children: (data: T, index: number) => ReactElement
}

export function List<T>({ data, keyField, children }: ListProps<T>) {
  return (
    <Stack gap={4} pt={4}>
      {data.map((item, index) => {
        return <Box key={item[keyField] as Key}>{children(item, index)}</Box>
      })}
    </Stack>
  )
}
