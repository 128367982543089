import { Components, Theme } from "@mui/material"

export const MuiFormControlLabel: Required<
  Components<Theme>
>["MuiFormControlLabel"] = {
  defaultProps: {
    componentsProps: {
      typography: {
        variant: "body2",
      },
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    }),
  },
}
