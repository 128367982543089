import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { PropsWithChildren } from "react"

interface InfoFieldProps {
  label: string
  testId: string
}

export const InfoField = ({
  label,
  children,
  testId,
}: PropsWithChildren<InfoFieldProps>) => (
  <Stack gap={0.5}>
    <Typography variant="body1" color="text.secondary">
      {label}
    </Typography>

    <Typography variant="body1" data-testid={testId}>
      <Subscribe fallback={<Skeleton />}>{children}</Subscribe>
    </Typography>
  </Stack>
)
