import { Components, Theme } from "@mui/material"

export const MuiButton: Required<Components<Theme>>["MuiButton"] = {
  defaultProps: {
    variant: "contained",
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      textTransform: "none",
      height: `${theme.constants.buttonHeight}px`,
      borderRadius: `${theme.constants.buttonHeight / 2}px`,
      flexGrow: 1,
    }),
  },
}
