import { Components, Theme } from "@mui/material"
import type {} from "@mui/x-data-grid/themeAugmentation"

export const MuiDataGrid: Required<Components<Theme>>["MuiDataGrid"] = {
  defaultProps: {
    rowHeight: 60,
    columnHeaderHeight: 30,
    disableColumnMenu: true,
    hideFooterPagination: true,
    disableRowSelectionOnClick: true,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      border: "none",
      backgroundColor: theme.palette.background.background0,
    }),
    columnHeaders: {
      borderBottom: "none",
    },
    columnHeader: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.text.secondary,
      "&:focus": {
        outline: "none",
      },
    }),
    columnSeparator: {
      display: "none",
    },
    row: {
      "&:last-child .MuiDataGrid-cell": {
        border: "none",
      },
    },
    cell: ({ theme }: { theme: Theme }) => ({
      border: "none",
      "&.none": {
        color: theme.palette.text.secondary,
      },
      "&.positive": {
        color: theme.palette.positive!.main,
      },
      "&.negative": {
        color: theme.palette.negative!.main,
      },
      "&:focus, &:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-actionsCell button": {
        border: `1px solid ${theme.palette.text.secondary}`,
      },
    }),
    footerContainer: {
      display: "none",
    },
  },
}
