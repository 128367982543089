import "./generated/muiModuleOverrides"

import { createTheme, ThemeOptions } from "@mui/material/styles"

import { components } from "./components"
import { constants } from "./constants"
import { AlpacaDark, AlpacaLight } from "./generated/themeOptions"

const baseTheme: ThemeOptions = {
  constants,
  components,
}

const lightTheme = createTheme({ ...baseTheme, ...AlpacaLight })

const darkTheme = createTheme({ ...baseTheme, ...AlpacaDark })

export const themes = { light: lightTheme, dark: darkTheme }
