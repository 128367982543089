import { Components, Theme } from "@mui/material"

export const MuiToggleButton: Required<Components<Theme>>["MuiToggleButton"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: "none",
      border: "none",
      // Adding important because there is a higher precedence rule defaulted for toggle button
      // to not have rounded corners on sides touching other buttons in the same button group
      borderRadius: "30px !important",
      color: theme.palette.text.secondary,
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.background1,
        boxShadow: theme.shadows[1],
      },
    }),
    sizeSmall: ({ theme }) => ({
      ...theme.typography.body2,
      padding: theme.spacing(),
    }),
    sizeMedium: ({ theme }) => ({
      ...theme.typography.body2,
    }),
  },
}
