import { Components } from "@mui/material"

export const MuiCssBaseline: Required<Components>["MuiCssBaseline"] = {
  styleOverrides: {
    body: {
      margin: 0,
      overflow: "hidden",
    },
  },
}
