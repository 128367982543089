import { Components, Theme } from "@mui/material"

export const MuiToggleButtonGroup: Required<
  Components<Theme>
>["MuiToggleButtonGroup"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      gap: theme.spacing(),
    }),
  },
}
