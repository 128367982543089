import { Components, Theme } from "@mui/material"

export const MuiTextField: Required<Components<Theme>>["MuiTextField"] = {
  defaultProps: {
    variant: "standard",
    InputLabelProps: {
      shrink: true,
    },
  },
}
