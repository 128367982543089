import { Components, LinkProps } from "@mui/material"

import { LinkBehavior } from "./LinkBehavior"

export const MuiLink: Required<Components>["MuiLink"] = {
  defaultProps: {
    // This supposedly doesn't exist in LinkProps, but works regardless.
    component: LinkBehavior,
  } as LinkProps,
}
