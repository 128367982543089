import { Components, Theme } from "@mui/material"

export const MuiFormLabel: Required<Components<Theme>>["MuiFormLabel"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    }),
  },
}
