import { PaletteMode, ThemeOptions } from "@mui/material"

import { AlpacaDark, AlpacaLight } from "./generated/themeOptions"

export * from "./theme"

const getSpacing = (options: ThemeOptions): number => {
  if (typeof options.spacing === "number") {
    return options.spacing
  } else {
    throw new Error("Could not extract spacing from theme options")
  }
}

export const baseSpacing: Record<PaletteMode, number> = {
  light: getSpacing(AlpacaLight),
  dark: getSpacing(AlpacaDark),
}
