import { Grid, Skeleton, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

interface InfoFieldProps {
  label: string
  doubleWidth?: boolean
  valueColor?: string
  "data-testid"?: string
}

export const InfoField = ({
  label,
  doubleWidth = false,
  valueColor,
  "data-testid": testId,
  children,
}: PropsWithChildren<InfoFieldProps>) => {
  const { t } = useTranslation()

  return (
    <Grid item xs={doubleWidth ? 10 : 5}>
      <Typography
        variant="h3"
        color="text.secondary"
        data-testid={`${testId}.label`}
      >
        {t(label)}
      </Typography>

      <Typography
        variant="body1"
        color={valueColor}
        data-testid={`${testId}.value`}
      >
        <Subscribe fallback={<Skeleton />}>{children}</Subscribe>
      </Typography>
    </Grid>
  )
}
