import { Components, Theme } from "@mui/material"

export const MuiLinearProgress: Required<
  Components<Theme>
>["MuiLinearProgress"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: "999px",
      backgroundColor: theme.palette.background.background1,
    }),
    bar: {
      borderRadius: "999px",
    },
  },
}
