import { Divider, Skeleton, Stack } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"

import { SummaryHeader } from "../SummaryHeader"
import { OrderHistoryGrid } from "./OrderHistoryGrid"

export const OrderHistory = () => {
  return (
    <Stack
      flexGrow={1}
      bgcolor={({ palette }) => palette.background.background0}
      p={({ spacing }) => spacing(4, 4)}
      gap={2.5}
    >
      <SummaryHeader title="orderHistory" />
      <Divider sx={{ height: "4px" }} />
      <Subscribe fallback={<Skeleton variant="rounded" height="100%" />}>
        <OrderHistoryGrid />
      </Subscribe>
    </Stack>
  )
}
